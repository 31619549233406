<!--
 * @Description: 动态判断文字超出则自动展示 tooltip
 * @Author: James324
 * @Date: 2023-09-28 19:17:16
 * @LastEditors: James324
 * @LastEditTime: 2024-01-22 10:39:57
-->
<script lang="ts">
import { createVNode, defineComponent, withDirectives, resolveDirective } from 'vue';

export default defineComponent({
    props: {
        /**
         * 传入的动态文本内容
         */
        content: {
            type: [String, Number]
        },
        slots: {
            default: () => {}
        }
    },

    render() {
        const textChild = this.$slots?.default?.();
        const showtipDirective = resolveDirective('showtip');

        const vNode = withDirectives(
            createVNode('div', { class: 'ellipsis' }, [
                createVNode('span', { class: 'ellipse-text' }, textChild || this.content)
            ]),
            [[showtipDirective, this.content]]
        );

        return vNode;
    }
});
</script>

<style lang="scss" scoped>
.ellipsis {
    :deep() {
        .el-popper {
            max-width: 400px;
            text-align: justify;
        }
    }
}
</style>
