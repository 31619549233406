/*
 * @Description: 路由列表
 * @Author: James324
 * @Date: 2023-08-18 13:34:57
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-23 11:38:32
 */
import { createRouter, createWebHashHistory } from 'vue-router';
import { constantRoute } from './routes';

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoute
});

export default router;
