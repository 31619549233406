<script setup lang="ts">
import { PropType } from 'vue';
import type { ButtonProps } from 'element-plus';
import QLoading from '@/components/q-loading/index.vue';

type QLoadingInstance = InstanceType<typeof QLoading>['$props'];
type QLoadingProps = PickComponentsProps<QLoadingInstance>;
const props = defineProps({
    saveButton: {
        type: Boolean,
        default: true
    },
    cancelButton: {
        type: Boolean,
        default: true
    },
    size: {
        type: String as any,
        default: 'large'
    },
    // 全屏模式
    fullscreen: {
        type: Boolean,
        default: false
    },
    // 粘贴吸顶
    sticky: {
        type: Boolean,
        default: true
    },
    loading: {
        type: Boolean,
        default: false
    },
    // 隐藏 label 占位
    hideLabel: {
        type: Boolean,
        default: false
    },
    // 关闭伪类
    closePseudo: {
        type: Boolean,
        default: false
    },
    // 按钮位置
    align: {
        type: String as PropType<'left' | 'center' | 'right'>,
        default: 'left'
    },
    // 保存按钮的其余属性，对应 element-plus 中 el-button 按钮属性
    okProps: {
        type: Object as PropType<Partial<ButtonProps>>,
        deault: () => {}
    },
    // 取消按钮的其余属性，，对应 element-plus 中 el-button 按钮属性
    cancelProps: {
        type: Object as PropType<Partial<ButtonProps>>,
        deault: () => {}
    },
    // 按钮 loading 属性
    loadingProps: {
        type: Object as PropType<QLoadingProps>
    }
});

const justifyContent = computed(() => {
    switch (props.align) {
        case 'center':
            return 'center';
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        default:
            return 'center';
    }
});

const emit = defineEmits(['save', 'cancel']);
</script>

<template>
    <div ref="target" :class="['submit', { 'is-sticky': sticky, 'close-pseudo': closePseudo }]">
        <el-row class="form-common" :style="{ 'justify-content': justifyContent }">
            <label v-if="!hideLabel"></label>
            <div class="submit__buttons">
                <el-button
                    v-if="saveButton"
                    type="primary"
                    :size="size"
                    :loading="loading"
                    v-bind="okProps"
                    @click="emit('save')"
                >
                    <template #loading>
                        <q-loading v-bind="loadingProps" />
                    </template>
                    保存
                </el-button>
                <el-button
                    v-if="cancelButton"
                    :size="size"
                    v-bind="cancelProps"
                    @click="emit('cancel')"
                    >取消</el-button
                >
            </div>
            <slot />
        </el-row>
    </div>
</template>

<style lang="scss" scoped>
@include b(submit) {
    overflow: hidden;

    @include when(sticky) {
        position: sticky;
        top: 0;
        z-index: 15;
        background: var(--q-bg-color);

        &::before,
        &::after {
            display: block;
            height: 8px;
            content: '';
            background: var(--q-bg-color);
        }

        &::after {
            height: 10px;
        }
    }

    &.close-pseudo {
        &::after,
        &::before {
            display: none !important;
        }
    }

    @include m(fixed) {
        position: absolute;
        bottom: 0;
        z-index: 11;
    }
}
</style>
