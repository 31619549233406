<!--
 * @Date: 2024-07-25 14:43:24
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-09-09 09:38:59
-->
<template>
    <Teleport to="body">
        <div v-if="modelValue" class="mask">
            <div
                class="dialog animate__animated animate__faster"
                :class="{ min, animate__zoomIn: visible, animate__zoomOut: !visible }"
                :style="{ width: px2rem(width) }"
            >
                <div class="dialog_title">
                    <!-- <div></div> -->
                    <div class="animate__animated animate__zoomIn animate__delay-1s">
                        {{ title }}
                    </div>
                    <el-icon class="title_close" @click="close"><Close /></el-icon>
                </div>

                <slot />
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { Close } from '@element-plus/icons-vue';
import { px2rem } from '@/utils';

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    width: {
        type: Number,
        default: 599
    },
    min: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: ''
    }
});

const visible = ref(false);
watch(
    () => props.modelValue,
    () => {
        visible.value = props.modelValue;
    }
);

const emit = defineEmits(['update:modelValue', 'close']);

const close = () => {
    visible.value = false;
    setTimeout(() => {
        emit('update:modelValue');
        emit('close');
    }, 300);
};

defineExpose({
    close
});
</script>

<style lang="scss" scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog {
    height: auto;
    border-radius: 20px;
    background: #fff;
    padding: 30px 40px 0;
    box-sizing: border-box;
    .dialog_title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Vendin;
        font-size: 30px;
        color: #000;
        --animate-delay: 0.2s;

        .title_close {
            position: absolute;
            transition: transform 0.5s;
            right: 0px;
            top: 3px;
            cursor: pointer;
            color: #666666;
            font-size: 20px;
        }

        .title_close:hover {
            color: #fabd00;
            transform: rotate(180deg);
        }
    }
}
.min {
    padding: 20px 30px 0;
}
</style>
