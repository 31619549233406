<!--
 * @Date: 2024-08-24 23:00:59
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-09-19 20:24:47
-->
<template>
    <div :class="{ hide: limit && fileList.length >= limit }" class="upload">
        <div v-if="disabled" class="disabled"></div>
        <el-upload
            v-model:file-list="fileList"
            :disabled="disabled"
            :http-request="handleRequest"
            list-type="picture-card"
            :accept="accept"
            :limit="limit"
            :on-remove="handleRemove"
        >
            <div class="info">
                <el-icon :size="36"><FolderAdd /></el-icon>
                <div>点击上传{{ type }}</div>
                <div class="text">支持: {{ upAccept }}</div>
            </div>

            <template v-if="type === '文件'" #file="{ file }">
                <div class="el-upload-list__item-thumbnail pdf">
                    <div class="pdf-close" @click="remove(file)">
                        <el-icon><CircleClose /></el-icon>
                    </div>
                    <div class="pdf-left">
                        <el-icon><Document /></el-icon>
                    </div>
                    <div class="pdf-right">
                        <div class="right-name">
                            {{ renderName(file.name) }}
                        </div>
                        <div class="right-info">
                            <div class="info-size">{{ renderSize(file.size) }}</div>

                            <div class="info-completed">
                                <el-icon color="#67c23a"><CircleCheckFilled /></el-icon> completed
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </el-upload>
    </div>
</template>

<script setup lang="ts">
import type { UploadProps, UploadUserFile, UploadRequestOptions } from 'element-plus';
import { upload } from '@/apis/upload';
import { FolderAdd, Document, CircleCheckFilled, CircleClose } from '@element-plus/icons-vue';
import axios from 'axios';

const props = withDefaults(
    defineProps<{
        accept: string;
        list?: any;
        limit?: number;
        type?: string;
        disabled: boolean;
    }>(),
    {
        type: '图片',
        list: []
    }
);
const emit = defineEmits(['change']);

const upAccept = computed(() => props.accept.toLocaleUpperCase());

const fileList = ref<UploadUserFile[]>([]);
/**
 * @description: 统一处理
 */
const handleList = (item: string) => {
    const nameArr = item.split('?');
    return { name: nameArr[nameArr.length - 1], url: item };
};
watch(
    () => props.list,
    () => {
        const { list } = props;
        if (!list || fileList.value.length) return;
        let data;
        if (Array.isArray(list)) {
            data = list.map((item: string) => {
                return handleList(item);
            });
        } else {
            data = [handleList(list)];
        }

        fileList.value = data;
    },
    {
        immediate: true
    }
);

/**
 * @description: 删除图片后的回调
 */
const handleRemove: UploadProps['onRemove'] = uploadFile => {
    Reflect.deleteProperty(urlObj.value, uploadFile.uid);
    emitChange();
};

const urlObj = ref<anyObj>({});
const handleRequest = async (options: UploadRequestOptions) => {
    const { file } = options;
    try {
        const { data } = await upload(file.name.replace(/\s*/g, ''));
        await axios.put(data, file);
        urlObj.value[file.uid] = data.split('?')[0];
        emitChange();
    } catch (error) {
        console.log('error', error);
    }
};

/**
 * @description: 统一处理emit
 */
const emitChange = () => {
    let emitData = Object.values(urlObj.value);
    if (props.limit === 1) {
        emitData = emitData[0];
    }
    emit('change', emitData);
};

/**
 * @description: 渲染文件大小
 */
const mb = 1048576;
const renderSize = (size: number) => {
    if (!size) return '';
    if (size > mb) {
        const currentMb = size / 1024 / 1024;
        return currentMb.toFixed(1) + 'mb';
    }
    const currentKb = size / 1024;
    return currentKb.toFixed(1) + 'kb';
};

const renderName = (name: string) => {
    const nameArr = name.split('/');
    return nameArr[nameArr.length - 1];
};

const remove = (file: UploadUserFile) => {
    console.log('file', file);
    fileList.value = fileList.value.filter(item => item.uid !== file.uid);
    Reflect.deleteProperty(urlObj.value, file.uid!);
    emitChange();
};
</script>

<style scoped lang="scss">
.hide {
    :deep(.el-upload--picture-card) {
        display: none;
    }
}

.upload {
    height: 100%;
    width: 100%;
    position: relative;

    .disabled {
        position: absolute;
        cursor: no-drop;
        z-index: 3;
        inset: 0;
        background: #788aa7;
        opacity: 0.1;
    }
    :deep(.el-upload-list__item-preview) {
        display: none !important;
    }

    :deep(.is-disabled) {
        .el-upload-list__item-actions:hover {
            opacity: 0;
        }
    }
}

.info {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 18px;
    .text {
        transform: scale(0.9);
    }
}
.pdf {
    position: relative;
    width: 295px;
    height: 103px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    .pdf-close {
        position: absolute;
        right: 4px;
        top: -1px;
        font-size: 16px;
        cursor: pointer;
        &:hover {
            color: #ff0000;
        }
    }

    .pdf-left {
        width: 58px;
        height: 56.045px;
        font-size: 60px;
    }
    .pdf-right {
        margin-left: 13px;
        flex: 1;
        width: 0;
        .right-name {
            font-size: 16px;
        }
        .right-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;
            line-height: 14px;
            .info-size {
                color: #a9acb4;
                font-size: 12px;
            }
            .info-completed {
                color: #292d32;
                font-size: 12px;
                display: flex;
                align-items: center;
                .el-icon {
                    margin-right: 6px;
                }
            }
        }
    }
}
</style>
