import { getDomList } from './dom.ts';

/**
 * 获取当前列是否包含错误样式，列处于错误激活
 */
export const haveErrorClass = (el: HTMLElement) => {
    return el.classList.contains('is-error');
};

/**
 * 重置校验样式
 */
export const removeErrorClass = (el: HTMLElement) => {
    el.classList.remove('is-error');
};

/**
 * 添加边框警告样式
 */
export const addErrorClass = (el: HTMLElement) => {
    el.classList.add('is-error');
};

/**
 * @: 通过传入 id 生成 class 类名
 * @param {string} id
 * @param {string} prefix
 * @return {*}
 */
export const generateClassToId = (id: string, prefix: string = 'valid__') => {
    return `${prefix}${id}`;
};

/**
 * 生成随机 id
 */
export const generateId = () => ~~(Math.random() * 1000000).toFixed(0);

/**
 * 随机生成有序递增 id
 */
export const generateRandomId = () => {
    const timestamp = Date.now().toString();
    const random = Math.floor(Math.random() * 1000)
        .toString()
        .padStart(3, '0');
    return timestamp + random;
};

/**
 * 隐藏校验文案
 */
export const hideValidErrText = (cb: () => void, parent: HTMLElement | Document = document) => {
    setTimeout(() => {
        const errorEl = getDomList('.is-error', parent);
        if (!errorEl!.length) {
            // 执行对应清除校验回调
            cb && cb();
        }
    });
};
