import { createVNode, render, type ComponentInternalInstance } from 'vue';
import QMessage from './index.vue';
import { MessageType, MessageTypeMap, TMessage } from './type';
type Instances = { id: string; vm: ComponentInternalInstance };
// 组件实例数组
const instances = ref<Instances[]>([]);

// id
let seed = 1;

// 消息弹框
export const message = function (content) {
    const container: HTMLDivElement = document.createElement('div');
    // 销毁
    const onDestroy = () => {
        render(null, container);
    };
    // 关闭
    const onClose = () => {
        const idx = instances.value.indexOf(instance);
        if (idx === -1) return;

        instances.value.splice(idx, 1);
        close();
    };
    const id = `qszr_message_${seed++}`;
    const vnode = createVNode(QMessage, { ...content, id, onClose, onDestroy });
    render(vnode, container);

    const instance: any = { id, vm: vnode.component! };
    instances.value.push(instance);

    document.body.appendChild(container.firstElementChild!);
    // 关闭弹框
    const close = () => {
        instance.vm.exposed.visible.value = false;
    };
    return close;
} as TMessage;

// 处理响应函数
const handleMessage = (type: MessageType['type']): any => {
    return function (option: Omit<MessageType, 'id' | 'type'> | string) {
        if (typeof option === 'string') {
            return message({
                text: option,
                type
            });
        }
        return message({
            ...option,
            type
        });
    };
};

Object.values(MessageTypeMap).forEach(item => {
    message[item] = handleMessage(item);
});

// 获取组件实例
export const getInstance = (id: string) => {
    const idx = instances.value.findIndex(instance => instance.id === id);
    const current = instances.value[idx];
    let prev: any;
    if (idx > 0) {
        prev = instances.value[idx - 1];
    }
    return { current, prev };
};

// 获取最后一个组件的bottom
export const getLastOffset = (id: string): number => {
    const { prev } = getInstance(id);
    if (!prev) return 0;
    return prev.vm?.exposed?.bottom.value || 0;
};

// 获取间距
export const getOffsetOrSpace = (id: string, offset: number) => {
    const idx = instances.value.findIndex(instance => instance.id === id);
    return idx > 0 ? 20 : offset;
};
