export const list = [10, 15, 20, 30, 60, 120, 180, 240, 360, 720, 1440, 2880, 4320];
import { generateId } from '@/utils';
import type { Records } from '@/api/plan/gantt/model/type';
// 时间刻度下拉框数据
export const scaleList = list.map(value => {
    let label;
    if (value < 60) {
        label = value + '分钟';
    } else if (value >= 60 && value < 1440) {
        label = value / 60 + '小时';
    } else {
        label = value / 1440 + '天';
    }
    return {
        value,
        label
    };
});

export const flattenArrays = (arr: Records[]) => {
    let newArr: Records[] = [];
    arr.forEach(function (obj: Records) {
        newArr.push({
            ...obj
        });
        if (obj.node) {
            newArr.push({
                ...obj.node
            });
            newArr = newArr.concat(flattenArrays(obj.node.children ?? []));
        }
    });
    return newArr;
};

export const addUniqueIds = (objArray: Records[] = []) => {
    objArray.forEach((obj: Records) => {
        obj.id = generateId(); // 为当前对象添加唯一的 ID
        if (obj.node) {
            obj.node.id = generateId();
            addUniqueIds(obj.node.children ?? []); // 递归调用处理子节点
        }
    });

    return objArray;
};
