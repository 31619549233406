import { useThrottleFn } from '@vueuse/core';

export default () => {
    const wrapperRef = ref<HTMLDivElement>();
    const wrapperH = ref(0);
    const wrapperW = ref(0);
    const wrapperT = ref(0);
    const observer = ref<ResizeObserver>();
    // 计算准确的渲染区域范围
    const handleObserve = () => {
        destroyObserver();
        if (!wrapperRef.value) return;
        wrapperT.value = wrapperRef.value.getBoundingClientRect().top;
        // 拿到渲染区域大小用来计算
        const observeContainer = useThrottleFn((entries: ResizeObserverEntry[]) => {
            entries.forEach(entry => {
                const cr = entry.contentRect;
                wrapperH.value = cr.height;
                wrapperW.value = cr.width;
            });
        });
        observer.value = new ResizeObserver(observeContainer);
        observer.value.observe(wrapperRef.value);
    };

    const destroyObserver = () => {
        observer.value?.disconnect?.();
    };

    onUnmounted(() => {
        destroyObserver();
    });
    return {
        wrapperRef,
        wrapperH,
        wrapperW,
        wrapperT,
        handleObserve
    };
};
