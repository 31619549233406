/*
 * @Date: 2024-07-24 17:31:14
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-23 14:21:09
 */
import { LocalStorage } from './storage';
import { GlobalStore } from '@/store';
import { GLOBAL_STATE, TAGS_VIEW_STATE, WhiteList } from '@/config';
import type { Route } from 'vue-router';

/**
 * @description: 获取用户信息
 * @return {*}
 */
export const getUserInfo = () => LocalStorage.get(GLOBAL_STATE)?.userInfo;

/**
 * @description: 移除 token
 * @return {*} void
 */
export const removeToken = () => {
    // console.log('globalStore', globalStore);
    GlobalStore().setUserInfo({});
    setTimeout(() => {
        LocalStorage.remove(GLOBAL_STATE); // 异步清除除 LOGIN_LOGIN_FORM 外全局状态
        LocalStorage.remove(TAGS_VIEW_STATE);
    }, 0);
};

/**
 * 设置路由 name 集合
 */
export const findNames = (Routes: Route[]) => {
    const result: string[] = [];
    function traverse(node: Route) {
        if (
            WhiteList.includes(node.path!) ||
            !node.name ||
            !node.path ||
            result.includes(node.name as string)
        )
            return;
        if (node.redirect) {
            if (node.redirect === node.path && node.children) {
                result.push(node.children[0].name as string);
            }
        } else {
            result.push(node.name as string);
        }

        if (node.children) node.children.forEach(traverse);
    }
    Routes.forEach(traverse);
    return result;
};
