/*
 * @Description: 状态管理及数据持久化
 * @Author: James324
 * @Date: 2023-08-18 10:58:10
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-23 14:20:49
 */

import { GLOBAL_STATE } from '@/config';
/**
 * StaffVO
 */
export interface StaffVO {
    id?: number;
    nickname?: string;
    permissions?: string[];
    roles?: string[];
    username?: string;
}

export const GlobalStore = defineStore(
    'GlobalState',
    () => {
        /* 登录模块 START */
        const token = ref('');
        const setToken = (data: string) => (token.value = data);

        const userInfo = ref<StaffVO>();
        const setUserInfo = (data: StaffVO) => (userInfo.value = data);

        const currentActive = ref('');

        const setActive = (data: string) => (currentActive.value = data);

        return {
            token,
            setToken,
            setUserInfo,
            userInfo,
            currentActive,
            setActive
        };
    },
    {
        persist: {
            key: GLOBAL_STATE
        }
    }
);
