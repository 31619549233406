/*
 * @Description: 静态路由
 * @Author: James324
 * @Date: 2023-08-24 17:00:30
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-09-10 12:01:54
 */

const Layout = () => import('@/views/layout/index.vue');

// 静态路由
export const constantRoute = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/login/index.vue'),
        meta: {
            title: '登录',
            hidden: true,
            hideLayout: true
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: Layout,
        meta: {
            title: '首页',
            hidden: true,
            hideCollapsed: true
        },
        children: [
            {
                path: '',
                name: 'DashboardSummary',
                level: 2,
                component: () => import('@/views/AffiliateDashboard/DashboardSummary.vue'),
                parent: 'Home',
                meta: {
                    title: 'DashboardSummary',
                    hidden: true
                }
            },
            {
                path: '/Property',
                name: 'Property',
                level: 2,
                component: () => import('@/views/Property/index.vue'),
                parent: 'Home',
                meta: {
                    title: '资产管理',
                    hidden: true
                }
            },
            {
                path: '/Property/Detail',
                name: 'Property-detail',
                level: 2,
                component: () => import('@/views/Property/details/index.vue'),
                parent: 'Home',
                meta: {
                    title: '资产详情',
                    hidden: true
                }
            },
            {
                path: '/Property/Detail',
                name: 'Property-detail',
                level: 2,
                component: () => import('@/views/Property/details/index.vue'),
                parent: 'Home',
                meta: {
                    title: '资产详情',
                    hidden: true
                }
            },
            {
                path: '/Behavior',
                name: 'Behavior',
                level: 2,
                component: () => import('@/views/Behavior/index.vue'),
                parent: 'Home',
                meta: {
                    title: '资产运营记录',
                    hidden: true
                }
            },
            {
                path: '/Staff',
                name: 'Staff',
                level: 2,
                component: () => import('@/views/Composite/staff/index.vue'),
                parent: 'Home',
                meta: {
                    title: '员工管理',
                    hidden: true
                }
            },
            {
                path: '/Staff/Create',
                name: 'Create',
                level: 2,
                component: () => import('@/views/Composite/staff/create.vue'),
                parent: 'Home',
                meta: {
                    title: '员工管理',
                    hidden: true
                }
            },

            // {
            //     path: '/File',
            //     name: 'File',
            //     level: 2,
            //     component: () => import('@/views/Composite/file/index.vue'),
            //     parent: 'Home',
            //     meta: {
            //         title: '文件管理',
            //         hidden: true
            //     }
            // },
            // {
            //     path: '/CustomerService',
            //     name: 'CustomerService',
            //     level: 2,
            //     component: () => import('@/views/AffiliateDashboard/CustomerService.vue'),
            //     parent: 'Home',
            //     meta: {
            //         title: 'CustomerService',
            //         hidden: true
            //     }
            // },
            // {
            //     path: '/AffiliateSettings',
            //     name: 'AffiliateSettings',
            //     level: 2,
            //     component: () => import('@/views/AffiliateDashboard/AffiliateSettings.vue'),
            //     parent: 'Home',
            //     meta: {
            //         title: 'AffiliateSettings',
            //         hidden: true
            //     }
            // },
            {
                path: 'Whitelist',
                name: 'Whitelist',
                level: 2,
                component: () => import('@/views/Whitelist/index.vue'),
                parent: 'Home',
                meta: {
                    title: 'Whitelist',
                    hidden: true
                }
            },
            {
                path: 'Whitelist/details',
                name: 'whitelist-detail',
                parent: 'Home',
                component: () => import('@/views/Whitelist/detail.vue'),
                meta: {
                    title: 'Whitelist',
                    hidden: true
                }
            },
            {
                path: 'Whitelist/rework',
                name: 'whitelist-rework',
                parent: 'Home',
                component: () => import('@/views/Whitelist/rework.vue'),
                meta: {
                    title: 'Whitelist',
                    hidden: true
                }
            },
            {
                path: 'ActivityManagemen',
                name: 'ActivityManagemen',
                parent: 'Home',
                component: () => import('@/views/ActivityManagemen/index.vue'),
                meta: {
                    title: '用户管理',
                    hidden: true
                }
            },
            {
                path: 'ActivityManagemen/detail',
                name: 'activityManagemen-detail',
                parent: 'Home',
                component: () => import('@/views/ActivityManagemen/detail.vue'),
                meta: {
                    title: '用户管理',
                    hidden: true
                }
            },
            {
                path: 'ActivityManagemen/managemen',
                name: 'activityManagemen-managemen',
                parent: 'Home',
                component: () => import('@/views/ActivityManagemen/managemen.vue'),
                meta: {
                    title: '活动管理',
                    hidden: true
                }
            },
            {
                path: 'PointsActivities/task',
                name: 'pointsActivities-task',
                parent: 'Home',
                component: () => import('@/views/PointsActivities/task.vue'),
                meta: {
                    title: '任务列表',
                    hidden: true
                }
            },
            {
                path: 'PointsActivities/user',
                name: 'pointsActivities-user',
                parent: 'Home',
                component: () => import('@/views/PointsActivities/user.vue'),
                meta: {
                    title: '任务列表',
                    hidden: true
                }
            },
            {
                path: 'PointsActivities/task/detail',
                name: 'pointsActivities-task-detail',
                parent: 'Home',
                component: () => import('@/views/PointsActivities/task-detail.vue'),
                meta: {
                    title: '任务列表',
                    hidden: true
                }
            },
            {
                path: 'PointsActivities/task/create',
                name: 'pointsActivities-task-create',
                parent: 'Home',
                component: () => import('@/views/PointsActivities/task-create.vue'),
                meta: {
                    title: '任务列表',
                    hidden: true
                }
            },
            {
                path: 'PointsActivities/task/revise',
                name: 'pointsActivities-task-revise',
                parent: 'Home',
                component: () => import('@/views/PointsActivities/task-revise.vue'),
                meta: {
                    title: '任务列表',
                    hidden: true
                }
            },
            {
                path: '/AtlasVault',
                name: 'AtlasVault',
                parent: 'Home',
                component: () => import('@/views/BeArtMiniWorldST/BeArtMiniWorldST.vue'),
                meta: {
                    title: 'BeArtMiniWorld管理',
                    hidden: true
                }
            },
            {
                path: '/AtlasVault/Level',
                name: 'Level',
                parent: 'AtlasVault',
                component: () => import('@/views/BeArtMiniWorldST/component/levelIndex.vue'),
                meta: {
                    title: '等级管理',
                    hidden: true
                }
            },
            {
                path: '/AtlasVault/BlindBox',
                name: 'BlindBox',
                parent: 'AtlasVault',
                component: () => import('@/views/BeArtMiniWorldST/blindBox/index.vue'),
                meta: {
                    title: '盲盒列表',
                    hidden: true
                }
            },
            {
                path: '/AtlasVault/AddBlindBox',
                name: 'AddBlindBox',
                parent: 'AtlasVault',
                component: () => import('@/views/BeArtMiniWorldST/component/blindBox.vue'),
                meta: {
                    title: '新增盲盒',
                    hidden: true
                }
            },
            {
                path: '/AtlasVault/Badge',
                name: 'Badge',
                parent: 'AtlasVault',
                component: () => import('@/views/BeArtMiniWorldST/BadgeIndex/index.vue'),
                meta: {
                    title: '徽章列表',
                    hidden: true
                }
            },
            {
                path: '/AtlasVault/AddBadge',
                name: 'AddBadge',
                parent: 'AtlasVault',
                component: () => import('@/views/BeArtMiniWorldST/component/badgeIndex.vue'),
                meta: {
                    title: '添加徽章',
                    hidden: true
                }
            }
        ]
    }
];

// 404
export const NotFoundRoute = [
    {
        path: '/:pathMatch(.*)',
        redirect: '/404',
        name: 'Any',
        meta: {
            hidden: true
        }
    }
];
