<!--
 * @Date: 2024-07-25 13:43:51
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-07-31 18:39:33
-->
<template>
    <div v-if="modelValue" class="copyTip animate__animated animate__faster" :class="type[mode]()">
        Copy successful
    </div>
</template>

<script setup>
import { onUnmounted } from 'vue';
import { watch, ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
    modelValue: Boolean,
    mode: {
        type: String,
        default: 'bottom'
    }
});

/**
 * @description: 方向对应动画
 */
const type = {
    bottom: () => ({
        animate__fadeInDown: show.value,
        animate__fadeOutUp: !show.value
    }),
    top: () => ({
        animate__fadeInUp: show.value,
        animate__fadeOutDown: !show.value
    }),
    left: () => ({
        animate__fadeInLeft: show.value,
        animate__fadeOutLeft: !show.value
    })
};

/**
 * @description: 动画显示
 */
const show = ref(false);
watch(
    () => props.modelValue,
    () => {
        if (props.modelValue) {
            show.value = props.modelValue;
            handleShow();
        }
    }
);

/**
 * @description: 动画关闭
 */
const emit = defineEmits(['update:modelValue']);
const handleShow = () => {
    setTimeout(() => {
        show.value = false;
        setTimeout(() => {
            emit('update:modelValue', false);
        }, 500);
    }, 3000);
};
onUnmounted(() => {
    show.value = false;
    emit('update:modelValue', false);
});
</script>

<style lang="scss" scoped>
.copyTip {
    color: #2db520;
    font-family: Quicksand;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
}
</style>
