<!--
 * @Description: 异步加载 Loading
 * @Author: James324
 * @Date: 2024-02-19 17:06:32
 * @LastEditors: James324
 * @LastEditTime: 2024-02-19 17:15:16
-->
<script setup lang="ts">
interface Props {
    size?: string;
    color?: string;
}
const props = withDefaults(defineProps<Props>(), {
    size: '24px',
    color: '#06f'
});
</script>

<template>
    <div class="m-page__loading">
        <q-loading v-bind="props" />
        <slot>
            <p>正在载入...</p>
        </slot>
    </div>
</template>

<style lang="scss" scoped>
@include b(m-page) {
    @include e(loading) {
        @include flex;

        flex-direction: column;
        padding: 40px 0 20px;

        & > p {
            padding-top: 14px;
            color: var(--q-text-color-regular);
        }
    }
}
</style>
