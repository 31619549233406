<script setup lang="ts">
import useFullscreen from '@/hooks/useFullscreen';

const props = defineProps({
    // 全屏挂载元素
    el: {
        type: String,
        default: '.m-page'
    }
});

/**
 * 全屏
 */
const { expand, handleFullscreen } = useFullscreen();
const handleToggle = () => {
    handleFullscreen(props.el, 'fullscreen');
};

const tipContent = computed(() => (expand.value ? '退出全屏' : '全屏'));
const iconClass = computed(() => (expand.value ? 'suoxiao1' : 'fangda1'));

defineExpose({
    expand
});
</script>

<template>
    <el-tooltip effect="dark" :content="tipContent" placement="top-end" :show-arrow="false">
        <div class="m-table__fullscreen m-l-16" @click="handleToggle">
            <q-icon :icon-class="iconClass" size="14" color="#8c8c8c" />
        </div>
    </el-tooltip>
</template>

<style lang="scss" scoped>
@include b(m-table) {
    @include e(fullscreen) {
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: rgb(132 157 196 / 10.2%);
        border-radius: 4px;

        @include flex();

        @include pseudo(hover) {
            background: var(--q-color-nav-hover);
        }
    }
}
</style>
