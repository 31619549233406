import { createVNode, render as vueRender, VNode } from 'vue';
import Modal from './confirmDialog/index.vue';
import { renderSomeContent } from './utils';
import QButton from './button/index.vue';
import destroyFns from './destroyFns';
import { type ModalFuncProps } from './hooks/useType';

const confirm = (config: ModalFuncProps) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        closable: false,
        width: '410px',
        bodyHeight: '140px',
        ...config,
        okButtonProps: {
            size: 'default'
        },
        cancelButtonProps: {
            size: 'default'
        },
        close,
        visible: true
    };
    let confirmDialogInstance: VNode | null = null;

    /**
     * 关闭 Modal
     */
    function close() {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                destroy();
            }
        };

        if (confirmDialogInstance) {
            Object.assign(confirmDialogInstance.component!.props, currentConfig);
            confirmDialogInstance.component!.update();
        }
    }

    /**
     * 销毁 Modal
     */
    function destroy() {
        if (confirmDialogInstance) {
            vueRender(null, container as any);
            confirmDialogInstance.component!.update();
            confirmDialogInstance = null;
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }

    /**
     * 按钮组
     */
    function renderButtons() {
        const {
            okText,
            okType = 'primary',
            cancelText = '取消',
            type,
            okCancel, // 取消按钮是否展示
            onCancel,
            onOk,
            okButtonProps,
            cancelButtonProps
        } = currentConfig;

        const mergedOkCancel = okCancel ?? type === 'confirm';

        return [
            mergedOkCancel
                ? createVNode(
                      QButton,
                      {
                          close,
                          actionFn: onCancel,
                          buttonProps: cancelButtonProps
                      },
                      {
                          default: () => renderSomeContent(cancelText)
                      }
                  )
                : null,
            createVNode(
                QButton,
                {
                    type: okType,
                    close,
                    actionFn: onOk,
                    buttonProps: okButtonProps
                },
                {
                    default: () => renderSomeContent(okText) || (mergedOkCancel ? '确定' : '知道了')
                }
            )
        ];
    }

    /**
     * 渲染 Modal
     */
    function render(props: ModalFuncProps) {
        const vm = createVNode(
            Modal,
            { ...props },
            {
                title: () => renderSomeContent(props.title || 'Confirm'),
                content: () => renderSomeContent(props.content),
                buttons: () => renderButtons()
            }
        );
        vm.appContext = props.parentContext || props.appContext || vm.appContext;
        vueRender(vm, container as any);
        return vm;
    }

    confirmDialogInstance = render(currentConfig);
    destroyFns.push(close);
    return {
        close
    };
};

export default confirm;
