<!--
 * @description: 基础表格
 * @Author: Peng_Yong
 * @Date: 2023-08-18 17:48:30
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-24 15:23:41
-->
<template>
    <div class="l-table">
        <div id="m-table" :key="tableKey" class="m-table" v-bind="$attrs">
            <div
                id="header-sticky-id"
                :class="[
                    'm-table__wrapper',
                    'header-sticky',
                    {
                        'sticky-modal': sticky,
                        'exclude-sticky': excludeSticky,
                        'detail-page__sticky': detailPageSticky,
                        'fullscreen--sticky': expand,
                        'no-flex': addTableRow
                    }
                ]"
            >
                <div class="m-table__main">
                    <el-table
                        :id="props.id || 'q-table'"
                        ref="tableRef"
                        highlight-current-row
                        :header-cell-class-name="`m-table__cell ${border ? '' : 'no-border'}`"
                        cell-class-name="m-table__body--cell"
                        row-key="id"
                        :border="border"
                        :height="height"
                        :max-height="minHeight"
                        :data="tableData"
                        :header-cell-style="{ height: '48px' }"
                        :row-style="{ height: '66px' }"
                        scrollbar-always-on
                        :indent="24"
                        style="width: 100%"
                        v-bind="customAttr"
                        @selection-change="handleSelectionChange"
                        @current-change="handleCurrentChange"
                        @row-click="handleRowClick"
                        @sort-change="(obj: any) => $emit('sortChange', obj)"
                        @header-dragend="(...args: any) => emit('headerDragend', ...args)"
                    >
                        <el-table-column v-if="tableConfig.expand" :resizable="false" type="expand">
                            <template #default="props">
                                <slot name="expand" :props="props" :config="tableConfig" />
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="tableConfig.selection"
                            type="selection"
                            reserve-selection
                            :fixed="false"
                            :resizable="false"
                            :width="props.selectionWidth || 40"
                            :selectable="handleSelectable"
                            class-name="no-resize"
                        />

                        <el-table-column
                            v-if="tableConfig.index"
                            label="序号"
                            type="index"
                            width="55px"
                            :fixed="tableConfig.indexFixed"
                        />

                        <template
                            v-for="item in tableConfig!.items"
                            :key="item.columnProps?.label || item.validProps?.id || item.slotName"
                        >
                            <template v-if="item.tableSlot">
                                <slot :name="item.tableSlot" :item="item" />
                            </template>

                            <el-table-column
                                v-else-if="item.columnProps"
                                v-bind="transformProps(item.columnProps)"
                                :fixed="item.fixed || item.columnProps.fixed"
                                :class-name="item.columnProps.cellClassName"
                                class="m-table__column"
                            >
                                <template #default="{ row, $index }">
                                    <template v-if="item.slotName === 'action'">
                                        <slot
                                            name="action"
                                            :row="row"
                                            :index="$index"
                                            :item="item"
                                        />
                                    </template>

                                    <template v-else-if="item.slotName">
                                        <slot
                                            :name="item.slotName"
                                            :row="row"
                                            :index="$index"
                                            :item="item"
                                        />
                                    </template>

                                    <div v-else>
                                        {{ row[item.modelKey!] ?? '--' }}
                                    </div>
                                </template>
                            </el-table-column>
                        </template>

                        <slot name="tableColumnSlot" />

                        <template #empty>
                            <div>
                                <QEmpty
                                    ref="emptyRef"
                                    :add-table-row="addTableRow"
                                    :modal-mode="modalMode"
                                    :height="toPx(emptyHeight)"
                                />
                            </div>
                        </template>
                    </el-table>
                    <slot name="custom" />
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <el-row v-if="showPage && !addTableRow && page" justify="end" class="m-table__pagination">
            <el-pagination
                :current-page="page.page"
                :page-size="page.size"
                :total="total"
                background
                layout="total, slot, sizes, prev, pager, next"
                :page-sizes="[10, 20, 50, 100]"
                @size-change="sizeChange"
                @current-change="currentChange"
            >
                <template #default>
                    <q-icon
                        icon-class="gengxinqi"
                        size="16px"
                        :class="['u-pagination-icon', { 'u-btn__loading': loading }]"
                        @click="
                            () => {
                                isRefresh = true;
                                getData?.();
                            }
                        "
                    ></q-icon>
                </template>
            </el-pagination>
        </el-row>
    </div>
</template>

<script setup lang="ts">
// import sortItem from './components/sortItem/index.vue';
import useSticky from '@/hooks/useSticky';
import useTableData from './hooks/useTableData';
import useTableHeight from './hooks/useTableHeight';
import { useMouse } from './hooks/useMouse';
import { useInit } from './hooks/useInit';
import { useTableAction } from './hooks/useTableAction';
import { useValidTable } from './hooks/useValid';
import { toPx } from '@/utils';
import type { BaseTableProps } from './type';

const props = withDefaults(defineProps<BaseTableProps>(), {
    editableKeys: () => [],
    mode: 'bottom',
    border: true, // 开启表格拖拽
    lineEdit: false, // 行内编辑
    addTableRow: false, // 表格数据新增
    modalMode: false, // modal 弹框模式
    showSetting: true,
    showPage: true,
    showFullScreen: false, // 显示全屏切换
    autoEditMode: false,
    closeEdit: false,
    hideDel: false,
    cellEdit: false, // 单元编辑
    unAction: true, // 隐藏操作列
    customTableData: false,
    initOnceSticky: false, // 初始化一次吸顶效果，只在元素挂载 mount 阶段执行一次。
    refreshCacheData: true, // 刷新缓存组件表格的数据
    maxAddNum: 50,
    closeDelRow: false, // 关闭删除行按钮
    tableKey: new Date().getTime(),
    validPopoverPosition: 'left',
    bodyRowHeight: '44px',
    maxHeight: () => 700
});

const emit = defineEmits([
    'selectionChange',
    'change',
    'del',
    'add',
    'edit',
    'row-click',
    'load',
    'selectChange',
    'sortChange',
    'headerDragend'
]);

const slots = useSlots();
const sticky = computed(() => props.modalMode && (props.addTableRow || !!slots.tableHeadStart)); // 可粘性定位
const excludeSticky = computed(
    () => props.modalMode && !props.addTableRow && !slots.tableHeadStart
); // 不可粘性定位

/**
 * 设置是否默认初始化添加一次表格新单元格选项
 */
const setInit = (val: boolean) => {
    isInit.value = val;
};

const { detailPageSticky } = useSticky(props.openSticky, props.initOnceSticky);

const {
    currentChange,
    sizeChange,
    getData,
    tableData,
    total,
    page,
    loading,
    isRefresh,
    setTableData,
    resetTableData
} = useTableData({ props, emit, setInit });

const emptyHeight = computed(() => {
    return +minHeight.value - 64;
});

/**
 * 初始化表格一系列操作
 */
const { customAttr, transformProps, editRef } = useInit(props, props.tableConfig!.items);

const { tableRef } = useMouse(props);

// 表格排序

const initData = () => {
    getData && getData();
};

const { minHeight } = useTableHeight(
    {
        modalMode: props.modalMode,
        addTableRow: props.addTableRow,
        maxHeight: props.maxHeight,
        id: props.id || 'q-table'
    },
    tableRef
);

onMounted(() => {
    initData();
    initWatchTableData();
});

onActivated(() => {
    // 如果开启了缓存表格数据则刷新表格数据
    if (props.refreshCacheData) {
        initData();
    }
});

/**
 * 字段校验是否通过
 */
const { errValidRef, handleValidFieldPass } = useValidTable(props, tableData, {
    tableRef,
    tableItems: props.tableConfig!.items
});

/**
 * 操作表格逻辑
 */
const fullscreenRef = ref();
const expand = computed(() => fullscreenRef.value && fullscreenRef.value.expand);
const {
    initWatchTableData,
    add,
    handleSelectionChange,
    handleCurrentChange,
    handleRowClick,
    selectTableRowData,
    handleSelectable,
    toggleRowSelection,
    toggleRowExpansion,
    tableToggleRowExpansion,
    setAsyncColumnWidth,
    setCurrentRow,
    getSaveData,
    isInit,
    getCellAddData,
    clearSelection,
    initAddRow
} = useTableAction(tableRef, {
    props,
    editRef,
    tableData,
    emit,
    tableItems: props.tableConfig!.items,
    expand,
    setInit,
    errValidRef
});

defineExpose({
    getData,
    add,
    getSaveData,
    handleValidFieldPass,
    editRef,
    errValidRef,
    tableData,
    setTableData,
    resetTableData,
    toggleRowSelection,
    toggleRowExpansion,
    tableToggleRowExpansion,
    setAsyncColumnWidth,
    setCurrentRow,
    selectTableRowData,
    setInit,
    getCellAddData,
    page,
    clearSelection,
    initAddRow,
    elTable: tableRef,
    getDataFn: props.getDataFn,
    tableItems: props.tableConfig!.items
});
</script>

<style scoped lang="scss">
@include b(l-table) {
    display: flex;
    flex: 1;
    flex-direction: column;
}

@include b(m-table) {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    transition: all 0.3s;

    @include e(pagination) {
        padding-top: 14px;
        margin-right: 20px;
    }

    @include e(header) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include e(main) {
        position: relative;
        height: 100%;

        @include m(mask) {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 56px;
            pointer-events: none;
            background: var(--q-bg-color-table-shadow);
        }
    }

    @include e(wrapper) {
        &:not(.no-flex) {
            flex: 1;
        }
    }
}

@include b(m-table) {
    @include e(scrollbar) {
        position: sticky;
        bottom: -35px;
        z-index: 12;
    }

    @include e(column) {
        position: relative;
    }
}

@include b(u-table) {
    @include e(required) {
        margin-right: 1px;
        color: #f00;
    }
}

@include b(u-pagination-icon) {
    margin-right: 0;
    margin-left: 12px;
    cursor: pointer;
}

@include b(u-table) {
    @include e(icon) {
        display: none;
        margin: 0 5px;
        cursor: pointer;
    }
}

@include b(action) {
    white-space: nowrap;
}

@include b(placeholder) {
    width: 6px;
    height: 6px;
}

@include b(m-tablehead) {
    @include m(end) {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

:deep() {
    @import '@/assets/style/common/reset-border.scss';

    .m-table__cell {
        &:not(.no-resize, .no-border) {
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                display: none;
                width: 1px;
                height: 100%;
                content: '';
                background: linear-gradient(180deg, rgb(32 45 64 / 0%), rgb(32 45 64 / 12%));
            }
        }

        &:hover {
            &:not(.no-resize, .no-border) {
                &::after {
                    display: block;
                }
            }
        }

        .cell {
            display: flex;
        }
    }

    /* 解决 el-table show-tooltip-overflow 编辑模式缩放未溢出展示问题 */
    .q-form-item__content {
        max-width: 99.9%;
    }

    .sticky-modal__header {
        position: sticky;
        top: -12px;
        z-index: 15;
        background: var(--q-bg-color);

        &::after {
            position: absolute;
            top: -16px;
            display: block;
            width: 100%;
            height: 16px;
            content: '';
            background: var(--q-bg-color);
        }
    }

    .detail-page__header--sticky {
        position: sticky;
        top: 64px;
        z-index: 15;
        background: var(--q-bg-color);

        &::after {
            position: absolute;
            top: -16px;
            display: block;
            width: 100%;
            height: 16px;
            content: '';
            background: var(--q-bg-color);
        }

        &.fullscreen--sticky {
            top: 0;
        }
    }

    .sticky-modal {
        .el-table__header-wrapper {
            top: 36px;
        }
    }

    .detail-page__sticky {
        .el-table__header-wrapper {
            top: 112px;
        }

        &.fullscreen--sticky {
            .el-table__header-wrapper {
                top: 48px;
            }
        }
    }

    .exclude-sticky {
        .el-table__header-wrapper {
            top: -24px !important;
        }
    }

    .opacityHeader {
        display: none;
    }

    .m-table {
        .submit.is-sticky {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
</style>
