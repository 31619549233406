<!--
 * @Description: 
 * @Author: shilinzhou
 * @Date: 2024-01-20 09:55:00
 * @LastEditors: James324
 * @LastEditTime: 2024-03-19 10:55:54
-->
<script setup lang="ts">
const getQuadrilateral = () => {
    return 'm-common__icon--quadrilateral';
};

const getTitle = () => {
    return 'm-common__titles';
};
</script>
<template>
    <div>
        <div class="m-common">
            <div class="m-common__icon">
                <span class="m-common__icon--start" :class="getQuadrilateral()"></span>
                <span :class="getQuadrilateral()"></span>
            </div>
            <div :class="getTitle()">
                <slot />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@include b(m-common) {
    position: relative;
    display: inline-block;
    min-width: 200px;
    text-align: justify;

    /* 四边形的布局 */
    @include e(icon) {
        display: flex;
        height: 12px;

        /* 四边形第一个的样式 */
        @include m(start) {
            margin: 0 40% 0 30px;
        }

        /* 四边形公共的样式  */
        @include m(quadrilateral) {
            width: 24px;
            background-color: var(--q-color-primary-light-5);
            transform: skewX(30deg);
        }

        /* 四边形暗黑模式公共的样式  */
        @include m(quadrilateralDark) {
            width: 24px;
            background-color: var(--q-color-primary-light-5);
            transform: skewX(30deg);
        }
    }

    /* 标题的样式 */
    @include e(titles) {
        padding: 4px 8px;
        font-size: 12px;
        color: var(--q-color-primary);
        background: var(--q-title-bg-color);
        border-radius: 2px;
    }

    /* 标题暗黑模式的样式 */
    @include e(titlesDark) {
        padding: 4px 8px;
        font-size: 12px;
        color: var(--el-text-color-primary);
        background-color: var(--q-color-primary);
        border-radius: 2px;
    }
}
</style>
