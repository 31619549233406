/*
 * @Date: 2024-09-09 23:31:37
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-09-10 10:08:00
 */
import request from '@/service/index';
import dayjs from 'dayjs';
export const upload = (filename: string) => {
    const day = dayjs().format('YYYYMMDD');
    return request.get(`/temporary-upload`, {
        params: {
            path: `public/${day}/${filename}`
        }
    });
};
