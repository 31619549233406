<template>
    <div
        class="animate_button"
        :class="{ enter, cancel, error, disabled }"
        @mouseenter="handleEnter"
        @mouseleave="enter = false"
        @click.stop="handleClick"
    >
        <div v-show="maskShow" class="mask"></div>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },

    cancel: {
        type: Boolean,
        default: false
    },

    unAnimate: {
        type: Boolean,
        default: false
    },

    error: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['click']);

const handleClick = () => {
    if (props.disabled) return;
    emit('click');
};

const enter = ref(false);
const maskShow = ref(false);
const handleEnter = () => {
    if (props.unAnimate) return;
    if (!maskShow.value) maskShow.value = true;
    enter.value = true;
};
</script>

<style lang="scss" scoped>
.animate_button {
    --background: #fabd00;
    --btn-color: #000;
    --mask-background: #000;
    --mask-color: #fff;

    user-select: none;
    border-radius: 6px;
    width: 286px;
    height: 50px;
    box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.1),
        0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    background: var(--background);
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    font-size: 18px;
    font-family: 'Vendin';
}

.cancel {
    --background: #000;
    --btn-color: #fff;
    --mask-background: #fabd00;
    --mask-color: #000;
}

.error {
    --background: #ff1818;
    --btn-color: #fff;
    --mask-background: #ab0c29;
    --mask-color: #fff;
}

.disabled {
    --background: #999;
    --btn-color: #fff;
    --mask-background: #ccc;
    --mask-color: #777;
}

.animate_button span {
    color: var(--btn-color);
    position: absolute;
    z-index: 1;
    line-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.animate_button:-moz-focusring {
    outline: auto;
}
.animate_button .mask {
    animation: move-out 0.5s;
    background: var(--mask-background);
    content: '';
    height: 150%;
    left: -25%;
    position: absolute;
    bottom: -180%;
    width: 150%;
    border-radius: 50%;
}
.enter .mask {
    animation: move-in 0.5s forwards;
}

.enter span {
    color: var(--mask-color);
    * {
        color: var(--mask-color);
    }
}

@keyframes move-in {
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

@keyframes move-out {
    0% {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(-200%);
    }
}
</style>
