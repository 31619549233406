import { getDom } from '@/utils';

export default () => {
    /* 全屏 */
    const expand = ref(false);

    const handleFullscreen = (dom: string, className: string = 'full-modal') => {
        const modalWrap = getDom(dom);
        modalWrap?.classList.toggle(className);

        nextTick(() => {
            if (modalWrap?.classList.contains(className)) {
                expand.value = true;
            } else {
                expand.value = false;
            }
        });
    };

    return {
        expand,
        handleFullscreen
    };
};
