<script lang="ts">
import type { SetupContext } from 'vue';

export default {
    inheritAttrs: false,
    props: ['width', 'height', 'close', 'afterClose', 'visible', 'closable', 'type', 'centered'],
    setup(props, { attrs }: SetupContext) {
        let IconClass: string = 'info';

        switch (props.type) {
            case 'info':
                IconClass = 'info';
                break;
            case 'success':
                IconClass = 'a-success1';
                break;
            case 'confirm':
            case 'warning':
                IconClass = 'warning';
                break;
            case 'error':
                IconClass = 'Subtract';
                break;
            default:
                break;
        }

        return {
            props,
            attrs,
            IconClass
        };
    }
};
</script>

<template>
    <Modal
        title=""
        :footer="false"
        :width="width"
        :height="height"
        :close="close"
        :afterClose="afterClose"
        :visible="visible"
        :closable="!!closable"
        :centered="centered"
        :style="attrs.style"
        class="m-modal-confirm"
        @cancel="close()"
    >
        <div class="m-modal-confirm__wrapper">
            <div :class="['m-modal-confirm__body', { 'is-closabled': closable }]">
                <span class="m-modal-confirm__icon"
                    ><q-icon :icon-class="IconClass" size="20px"
                /></span>
                <span class="m-modal-confirm__title">
                    <slot name="title"></slot>
                </span>
                <div class="m-modal-confirm__content">
                    <slot name="content"></slot>
                </div>
            </div>
            <div class="m-modal-confirm__btns">
                <slot name="buttons"></slot>
            </div>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
@include b(m-modal-confirm) {
    @include e(body) {
        min-height: 77px;
        padding-bottom: 12px;

        @include when(closabled) {
            padding-top: 24px;
        }
    }

    @include e(icon) {
        float: left;
        margin-top: 1px;
        margin-right: 12px;
        line-height: 0;
    }

    @include e(title) {
        display: block;
        overflow: hidden;
        font-size: 16px;
        line-height: 24px;
        color: var(--q-text-color-regular);
    }

    @include e(content) {
        margin-top: 12px;
        margin-left: 34px;
        font-size: 14px;
        line-height: 24px;
        color: var(--q-text-color-secondary);
    }

    @include e(btns) {
        text-align: right;
    }

    @include e(centered) {
        text-align: center;

        @include pseudo(before) {
            display: inline-block;
            width: 0;
            height: 100%;
            vertical-align: middle;
            content: '';
        }

        @include b(m-modal) {
            top: 0;
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }
}
</style>
