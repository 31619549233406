/**
 * v-scrollbar
 * 放在 m-table 标签上 使其插入 m-table 底部
 * 接收参数：Boolean
 * v-scrollbar="true"
 */
import type { Directive, DirectiveBinding } from 'vue';

export const scrollbar: Directive = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value) {
            const bar = el?.querySelector('.el-scrollbar__bar.is-horizontal');
            const parentBar = el?.querySelector('.m-table__scrollbar');
            if (bar && parentBar) {
                bar?.classList?.add('q-scroll__el-scrollbar__bar');
                parentBar?.appendChild(bar);
            }
        }
    }
};
