import { ElRow } from 'element-plus';
import useTableHeight from '@/hooks/useTableHeight';

export default (handleObserve: () => void) => {
    const contentRef = ref<HTMLDivElement>();
    const paginationRef = ref<InstanceType<typeof ElRow>>();

    const contentHeight = ref(0); //高度
    /**
     * @: 计算高度逻辑
     */
    const computedHeight = () => {
        if (contentRef.value) {
            const windowH = window.innerHeight; // 可视窗口高度
            const ganttContent = useElementBounding(contentRef);
            const pagination = useElementBounding(paginationRef);
            const spac = 36 + 35; //间距
            if (ganttContent) {
                contentHeight.value =
                    windowH - ganttContent?.top.value - pagination.height.value - spac;
            }
            handleObserve();
        }
    };

    // 统一计算高度
    useTableHeight(computedHeight, undefined, contentRef);

    onMounted(() => {
        setTimeout(() => {
            computedHeight();
        }, 1000);
    });

    return {
        contentRef,
        paginationRef,
        contentHeight
    };
};
