<!--
 * @Description: tooltips 文字帮助提示框
 * @Author: James324
 * @Date: 2023-10-10 13:08:09
 * @LastEditors: James324
 * @LastEditTime: 2024-01-11 14:31:14
-->
<script setup lang="ts">
import { QuestionFilled } from '@element-plus/icons-vue';
import type { ToolTip } from './type';

const props = withDefaults(defineProps<ToolTip>(), {
    size: 16,
    trigger: 'hover',
    placement: 'top'
});
const iconSize = computed(() => {
    return typeof props.size === 'number' ? props.size + 'px' : props.size;
});
</script>

<template>
    <el-tooltip :placement="placement" :trigger="trigger" v-bind="$attrs" :show-arrow="false">
        <template #content>
            <p class="u-tooltip__content">{{ tipMsg }}</p>
        </template>
        <i class="m-tooltip">
            <q-icon v-if="tip" :size="iconSize" icon-class="_wenhaoxiao" class="u-question"
                ><QuestionFilled
            /></q-icon>
        </i>
    </el-tooltip>
</template>

<style lang="scss" scoped>
@include b(u-tooltip) {
    @include e(content) {
        max-width: 140px;
    }
}

@include b(m-tooltip) {
    display: inline-flex;
    align-items: center;
}

@include b(u-question) {
    margin: 0 4px;
    color: var(--q-text-color-secondary);
    cursor: pointer;
}
</style>
