import IScroll from 'iscroll/build/iscroll-probe';

import timeLine from '../components/time-line.vue';

export default () => {
    const scroller = ref<any>();
    const timeLineRef = ref<InstanceType<typeof timeLine>>();
    const leftBarRef = ref<HTMLDivElement>();
    const scrollLeft = ref(0);
    const scrollTop = ref(0);
    /**
     * @:滚动时滚动时间和table
     * @return {*}
     */
    const scrollHandler = () => {
        if (!scroller.value) return;

        // iscroll 内部有时会出现nan然后组件崩溃，出现后销毁并新建组件
        if (isNaN(scroller.value.x) || isNaN(scroller.value.y)) {
            initScroller();
            return;
        }
        if (timeLineRef.value && leftBarRef.value) {
            timeLineRef.value.$el.style.transform = `translateX(${scroller.value.x}px)`;
            leftBarRef.value.style.transform = `translateY(${scroller.value.y}px)`;
            scrollLeft.value = -scroller.value.x;
            scrollTop.value = -scroller.value.y;
        }
    };

    const initScroller = () => {
        destroy();
        scroller.value = new IScroll('#iscroll', {
            probeType: 3,
            click: true,
            scrollX: true,
            scrollY: true,
            freeScroll: false,
            mouseWheel: true,
            scrollbars: false,
            interactiveScrollbars: false,
            fadeScrollbars: false,
            bounce: false,
            deceleration: 0.01
        });
        scroller.value.on('scroll', scrollHandler);
    };

    const scrollRefresh = () => {
        if (!scroller.value) return;
        // 提前刷新滚动条会导致滚动条计算失败
        setTimeout(() => {
            scroller.value?.refresh();
        }, 500);
    };

    const destroy = () => {
        scroller.value?.destroy?.();
        scroller.value = null;
    };

    onMounted(() => {
        initScroller();
    });
    onUnmounted(() => {
        destroy();
    });
    return {
        scroller,
        timeLineRef,
        leftBarRef,
        scrollLeft,
        scrollTop,
        scrollRefresh,
        initScroller
    };
};
