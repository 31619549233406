/*
 * @Date: 2024-07-24 17:31:14
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-23 11:42:47
 */
import router from '@/router';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
export const toLogin = () => {
    router.replace({
        path: '/'
    });
};

/**
 * 获取页面标题
 */
export const getTitle = (route: RouteLocationNormalizedLoaded) => {
    return route.meta.title as string;
};
