<!--
 * @Date: 2024-08-02 19:16:08
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-04 09:14:30
-->
<script setup lang="ts">
import Quill from 'quill';
import { defineProps } from 'vue';

/*
 *variable
 */
const props = defineProps({
    id: {
        type: String,
        default: 'editor'
    },
    modelValue: {
        type: String,
        default: ''
    }
});

let quill: Quill;

const editor = ref();
const emit = defineEmits(['update:modelValue']);
onMounted(() => {
    quill = new Quill(`#${props.id}`, {
        theme: 'snow',
        modules: {
            toolbar: [
                'bold',
                'italic',
                'underline',
                { header: [1, 2, 3, 4, 5, 6] },
                'blockquote',
                'code-block',
                'code',
                'link',
                { list: 'ordered' },
                { list: 'bullet' },
                'image',
                { size: [] }
            ],
            keyboard: {
                bindings: {
                    strike: {
                        key: 'S',
                        ctrlKey: true,
                        shiftKey: true,
                        handler: function (range, context) {
                            // 获取当前光标所在文本的格式
                            const format = this.quill.getFormat(range);
                            // 增加/取消删除线
                            this.quill.format('strike', !format.strike);
                        }
                    }
                }
            },

            // 新增的
            history: {
                delay: 2000, // 2s记录一次操作历史
                maxStack: 200 // 最大记录200次操作历史
            }
        }
    });
    quill.on('text-change', () => {
        const text = quill.getText();
        emit('update:modelValue', text);
    });
    quill.setText(props.modelValue, 'user');
});
</script>

<template>
    <div :id="props.id" ref="editor"></div>
</template>

<style>
@import 'quill/dist/quill.snow.css';
</style>
