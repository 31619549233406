<template>
    <div class="m-noAuth">
        <div class="m-noAuth__img"></div>
        <h2>暂无查看权限，请联系管理员进行配置！</h2>
        <div class="m-t-14">
            <el-button type="primary" @click="$router.push('/')">返回</el-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@include b(m-noAuth) {
    @include flex;

    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include e(img) {
        width: 242px;
        height: 223px;
        background: url('./img/no_auth.webp') center no-repeat;
        background-size: contain;
    }

    h2 {
        margin-bottom: 18px;
        font-size: 14px;
    }
}
</style>
