/*
 * @Description: 表格表头定义 hook
 * @Author: James324
 * @Date: 2023-09-18 19:08:48
 * @LastEditors: James324
 * @LastEditTime: 2023-12-11 18:10:49
 */
export default function (cb: () => void) {
    const toolRef = ref();

    /**
     * 设置按钮离开可视区，执行 popover 隐藏操作
     */
    const { stop } = useIntersectionObserver(
        toolRef,
        ([{ isIntersecting }]) => {
            if (!isIntersecting) {
                cb();
            }
        },
        { threshold: 0.5 }
    );

    onUnmounted(() => stop());

    return {
        toolRef,
        stop
    };
}
