import { toPascalCase } from '@/utils';
import type { App, DefineComponent } from 'vue';

const modules = import.meta.glob(
    [
        '../components/**/*.vue',
        '!**/q-message',
        '!**/q-avatar/avatar.vue',
        '!**/components',
        '!**/confirmDialog',
        '!**/content',
        '!**/hooks',
        '!**/mask',
        '!**/modal',
        '!**/button'
    ],
    {
        eager: true
    }
);

// 处理组件
const handleComponents = () => {
    const arr: { name: string; component: DefineComponent }[] = [];
    let key: string;

    for (key in modules) {
        const nameArr = key.split('/');
        const name = nameArr[nameArr.length - 2];
        arr.push({
            name: toPascalCase(name),
            component: (modules[key] as DefineComponent).default
        });
    }
    return arr;
};

export default {
    install(app: App) {
        handleComponents().forEach(item => {
            app.component(item.name, item.component);
        });
    }
};
