import { AxiosResponse } from 'axios';
import { message } from '@/components/q-message/message';
import { removeToken, toLogin } from '@/utils';

/**
 * 处理后端返回对应 code 编码对应逻辑
 */
class Func {
    // 请求成功
    static success(res: AxiosResponse<any>) {
        return Promise.resolve(res);
    }

    // 登录失效
    static noLogin(res: AxiosResponse<any>) {
        removeToken();
        toLogin();

        let errMsg = '';
        switch (res.data.code) {
            case 2:
                errMsg = '请进行登录';
                break;
            case 3:
            case 5:
            case 6:
                errMsg = '请重新登录';
                break;
            case 8:
                errMsg = '密码已更新，请重新登录';
                break;
            case 10:
                errMsg = '当前账户已在其它地方登录，请重新登录';
                break;
            case 12:
                errMsg = '无效token';
                break;
            case 23:
                errMsg = '用户没有任何权限，请联系管理员配置权限';
                break;
            default:
        }

        return message.error(errMsg || res.data.msg || '登录失效，请重新登录！');
    }

    // 未授权
    static noAuth(res: AxiosResponse<any>) {
        message.error('暂无权限，请联系管理员进行配置！');
        return Promise.reject(res);
    }
}

/**
 * @: 处理请求参数中的空字符串
 * @param {any} data
 * @return {*}
 */
export const removeEmptyAttr = (data: any): any | null => {
    if (data === null || data === '') {
        return null;
    }
    if (typeof data !== 'object') {
        return data;
    }
    if (Array.isArray(data)) {
        for (let index = 0; index < data.length; index++) {
            let value = data[index];
            if (typeof value === 'string' || typeof value === 'object') {
                value = removeEmptyAttr(value);
            }
            value == null && data.splice(index--, 1);
        }
    } else {
        for (const key in data) {
            let value = data[key];
            if (typeof value === 'string' || typeof value === 'object') {
                value = removeEmptyAttr(value);
            }
            value == null && delete data[key];
        }
    }
    return data;
};

interface CodeCollection {
    [key: number]: any;
}
export const codeCollection: CodeCollection = {
    200: Func.success, // 成功
    2: Func.noLogin, // 未登录
    3: Func.noLogin, // token失效
    4: Func.noLogin, // 无感刷新 token
    5: Func.noLogin, // 刷新 token 不可用
    6: Func.noLogin, //  刷新 Token 已过期，重新登录
    7: Func.noAuth, // 未授权, 权限不足拒绝访问
    8: Func.noLogin, // 账号资料已过期，需要重新登录（包括重置密码登）
    10: Func.noLogin, // Token 发生变化，已在其他地方登录
    12: Func.noLogin, // 无效 token
    23: Func.noLogin, // 未被分配任何权限，请管理员配置权限
    14: Func.noAuth // 未授权, 权限不足拒绝访问
};
