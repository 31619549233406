import type { Result } from '@/service/request/type';
import { initAddRow, initDefault } from './useTableAction';

interface Props extends anyObj {
    getDataFn?: (params: anyObj) => Promise<Result>;
    addedParams?: anyObj;
    handleTableData?: ((params: any) => any[]) | ((params: any) => Promise<any[]>);
}
interface Args {
    props: Props;
    emit: any;
    setInit: (val: boolean) => void;
}
export default ({ props, emit, setInit }: Args) => {
    const tableData = ref<any>([]);
    const searchParams = ref<anyObj>({});
    const page = ref({ page: 1, size: 20 });
    const total = ref(0);
    const loading = ref(false);
    const isRefresh = ref(false);
    const orderSort = ref<anyObj>({});

    // 获取数据
    const getData = async (params?: anyObj) => {
        if (!props.getDataFn) return { tableData };
        if (params) {
            searchParams.value = params;
        }
        try {
            loading.value = true;
            /**
             * @:TODO:
             *  ...props.addedParams,
                ...page.value,
                ...searchParams.value
                三个位置不能调整
             * @return {*}
             */
            let params = {
                ...props.addedParams,
                ...page.value,
                ...searchParams.value,
                ...orderSort.value
            };
            if (props.handleParams) {
                params = props.handleParams(params);
            }

            const { data } = await props.getDataFn(params);
            if (data) {
                let currentData: any = data;
                if (props.customDataKey) {
                    currentData = data[props.customDataKey];
                }
                if (props.handleTableData) {
                    tableData.value = await props.handleTableData(data);
                } else {
                    // 默认将字段未空字符串 '' 转换为 null 方便第一次校验使用
                    tableData.value = currentData.list;
                }
                total.value = currentData.total;

                // 等数据完全添加在通知，避免表格数据不全
                nextTick(() => {
                    emit('load', currentData);
                });
            }
        } catch (error) {
            return error;
        } finally {
            loading.value = false;
            isRefresh.value = false;
        }
    };

    const sizeChange = (e: number) => {
        page.value.size = e;
        console.log('page.value.size', page.value.size);
        getData(searchParams.value);
    };

    const currentChange = (e: number) => {
        page.value.page = e;
        getData(searchParams.value);
    };

    /**
     * 重新设置 tableData 值
     * @param {boolean} isEdit 值若为 true 则表示表格是可编辑模式展示，反之表格为不可编辑模式展示
     */
    const setTableData = (data: anyObj[], isEdit?: boolean) => {
        const setData = data || [];
        if (!Object.keys(data?.[0] || {}).length) {
            initDefault(data[0], props.tableConfig.items);
        }
        tableData.value = setData.map(item => {
            const addRowData = isEdit ? initAddRow() : {};
            return { ...item, ...addRowData };
        });
        emit('add', tableData.value);
        setInit(false); // 避免数据复制时新增数据出现重复
    };

    /**
     * 重置表格数据
     */
    const resetTableData = () => {
        setTableData([]);
    };

    return {
        currentChange,
        sizeChange,
        getData,
        tableData,
        total,
        page,
        loading,
        isRefresh,
        orderSort,
        setTableData,
        resetTableData
    };
};
